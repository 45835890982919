
import React, { useState, useContext, useEffect } from "react"
import { FirebaseContext, useAuth } from "../../firebase"
import { navigate } from "gatsby"
import { useMicroCopyFi } from "../../hooks/microCopy"
import GetMicroCopy from "../../shared/getMicroCopy"
import Spinner from "../UI/Spinner/Spinner"

import Box from "@material-ui/core/Box"
import * as styles from "./form.module.scss"

import { checkValidity } from "../../shared/utility"

const ProfileForm = ({}) => {
  const microCopyTexts = useMicroCopyFi
  const { user, loading } = useAuth()
  const [ firstName, setFirstName] = useState("");
  const [ lastName, setLastName] = useState("");
  const [ email, setEmail] = useState("");
  const [ newsletter, setNewsletter] = useState("");
  const [ phoneNumber, setPhoneNumber] = useState("");
  const [ password, setPassword] = useState("");
  const [ confirmPassword, setConfirmPassword] = useState("");

  let urlParams

  if (typeof window !== "undefined") {
    urlParams = new URLSearchParams(window.location.search)
  }
  
  const isApp = urlParams ? urlParams.get('app') : null
  const uid = urlParams ? urlParams.get('uid') : null
  const { firebase } = useContext(FirebaseContext)
  const [errorMessage, setErrorMessage] = useState("")
  const [pwdErrorMessage, setPwdErrorMessage] = useState("")
  const [accountErrorMessage, setAccountErrorMessage] = useState("")

  const labelErrorFirstName = GetMicroCopy(microCopyTexts, "lomakeVirheEtunimi")
  const labelErrorLastName = GetMicroCopy(microCopyTexts, "lomakeVirheSukunimi")
  const labelErrorEmail = GetMicroCopy(microCopyTexts, "lomakeVirheSähköposti")
  const labelErrorPassword = GetMicroCopy(microCopyTexts, "lomakeVirheSalasana")
  useEffect(() => {
    async function getUser(email) {
      const response = await firebase.getUser({email: user.email})
      setFirstName(response.data ? response.data.first_name : "")
      setLastName(response.data ? response.data.last_name : "")
      setEmail(response.data ? response.data.email : user.email)
      setNewsletter(response.data ? response.data.newsletter : false) // this is boolean
    }
    async function getPhone(email) {
      const response = await firebase.getPhone({email: user.email})
      setPhoneNumber(response.data ? response.data.phone_number : " ")
    }
    if (!loading && !user) {
      if (isApp && !uid) {
        navigate("/login/?app=true", { state: { returnUrl: '/profile' } })
      }
      else {
        navigate("/login", { state: { returnUrl: '/profile' } })
      }
    }
    if (firebase && user) {
      getUser({email: user.email})
      getPhone({email: user.email})
    }
  }, [loading, user, firebase])


  function handelInputChange(e) {
    e.persist()
    setErrorMessage("")
    switch (e.target.name) {
      case "firstName":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorFirstName)
        } else {
          setErrorMessage("")
          setFirstName(e.target.value)
        }
        break
      case "lastName":
        if (!checkValidity(e.target.value, { required: true })) {
          setErrorMessage(labelErrorLastName)
        } else {
          setErrorMessage("")
          setLastName(e.target.value)
        }
        break
      case "email":
        if (!checkValidity(e.target.value, { required: true, isEmail: true })) {
          setErrorMessage(labelErrorEmail)
        } else {
          setErrorMessage("")
          setEmail(e.target.value)
        }
        break
      case "phoneNumber":
        setPhoneNumber(e.target.value)
        break
      case "newsletter":
        if (e.target.checked) { 
          setNewsletter(true) 
        } else {
          setNewsletter(false) 
        }
        break
      default:
        break
    }
  }

  function updateUser({
    email,
    first_name,
    last_name,
    terms_and_conditions_acceptance,
    newsletter,
  }) {
    return firebase.updateUser({
      email: email,
      first_name: first_name,
      last_name: last_name,
      terms_and_conditions_acceptance: terms_and_conditions_acceptance,
      newsletter: newsletter,
    })
  }

  function updatePhone({
    email,
    phone_number,
  }) {
    return firebase.updatePhone({
      email: email,
      phone_number: phone_number,
    })
  }

  function deleteUser({
    email,
  }) {
    return firebase.deleteUser({
      email: email,
    })
  }

  function  deletePhone({
    email,
  }) {
    return firebase. deletePhone({
      email: email,
    })
  }

  function logout() {
    return firebase.logout()
  }

  function handleSubmit(e) {
    e.preventDefault()
    updateUser({
      email: email,
      first_name: firstName,
      last_name: lastName,
      terms_and_conditions_acceptance: true,
      newsletter: newsletter
    }).then(ref => {
    updatePhone({
      email: email,
      phone_number: phoneNumber,
    })}).then(ref => { 
      setErrorMessage("Tiedot päivitetty!")
    })
    .catch(err => {
      setErrorMessage("Virhe!")
    })
  }

  function removeUser(e) {
    deleteUser({
      email: email
    }).then(() => {
      deletePhone({
        email: email
      }).then(() => {
        user.delete().then(() => {
        setAccountErrorMessage("Käyttäjätili on poistettu!")
        logout()
      })
    })
  })
  .catch((error) => {
    setAccountErrorMessage("Käyttäjätilin poisto ei onnistunut!")
  });
  }

  function handlePasswordChange(e) {
    e.preventDefault()
    setPwdErrorMessage("")
    if (password && password == confirmPassword) {
      user.updatePassword(password).then(function() {
        setPwdErrorMessage("Salasana on vaihdettu!")
        setPassword("")
        setConfirmPassword("")
        return user;
     }).catch(function(error) {
       setPwdErrorMessage("Istunto on vanhentunut!")
       logout()
     });
    } else {
      setPwdErrorMessage("Salasanat eivät täsmää!")
    }
  }

  return (
    <section className="max-w-3xl mx-auto text-center mt-20">
        <header>
          <div>
            <h1>Oma profiili</h1>
          </div>
        </header>
        
        {loading && <Spinner />}
        {loading && (
          <div>Ladataan käyttäjätietoja</div>
        )}

        {!loading && (
        <Box
          component="section"
          className={styles.form}
          style={{ padding: "0 20px", maxWidth: "1240px", margin: "85px auto" }}
        >

          <Box component="div" className={styles.formContainer}>
            <h2>Omat tiedot</h2>
            <form onSubmit={handleSubmit}>
            <div className={styles.formRow}>
                <label className="text-left">
                  Etunimi
                </label>
                <input
                  value={firstName}
                  name="firstName"
                  onChange={handelInputChange}
                  type="text"
                ></input>
              </div>

              <div className={styles.formRow}>
                <label className="text-left">
                  Sukunimi
                </label>
                <input
                  value={lastName}
                  name="lastName"
                  onChange={handelInputChange}
                  type="text"
                ></input>
              </div>
            
              <div className={styles.formRow}>
                <label className="text-left">
                  Sähköposti
                </label>
                <input
                  value={email}
                  name="email"
                  onChange={handelInputChange}
                  type="email"
                  required
                  disabled
                ></input>
              </div>

              <div className={styles.formRow}>
                <label className="text-left">
                  Puhelin
                </label>
                <input
                  value={phoneNumber}
                  name="phoneNumber"
                  onChange={handelInputChange}
                  type="text"
                ></input>
              </div>

                <div className={styles.formRow}>
                  <label className="text-left">
                    Tilaa uutiskirjeemme <span> </span>
                    <input
                      onChange={handelInputChange}
                      checked={newsletter ? "checked" : ""} 
                      type="checkbox"
                      name="newsletter"
                    />
                  </label>
                </div>

                <p className={styles.errorMessage}>{errorMessage}</p>

                <button type="submit">Tallenna muutokset</button>
                
            </form>
            
            <h2>Vaihda salasana</h2>

            <form onSubmit={handlePasswordChange}>
              <div className={styles.formRow}>
              <label className="text-left">
                    Uusi salasana
                  </label>
                  <input
                    name="password"
                    type="password"
                    onChange={event => setPassword(event.target.value)}
                    value={password}
                  ></input>
              </div>

              <div className={styles.formRow}>
              <label className="text-left">
                    Uusi salasana uudelleen
                  </label>
                  <input
                    name="confirmPassword"
                    type="password"
                    onChange={event => setConfirmPassword(event.target.value)}
                    value={confirmPassword}
                  ></input>
              </div>

              <p className={styles.errorMessage}>{pwdErrorMessage}</p>

              <button type="submit">Tallenna muutokset</button>
            </form>


            <footer>
              <p className={styles.errorMessage}>{accountErrorMessage}</p>
              <button onClick={removeUser}>Poista käyttäjätili</button>
            </footer>
            </Box>
          </Box>
        )}

    </section>
  )
}

export default ProfileForm

