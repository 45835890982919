import React from "react";
import ProfileForm from "../components/forms/profileForm";
import LayoutController from "../components/layoutController"

const Profile = () => {
  return (
    <LayoutController language="fi"> 
    <ProfileForm />
    </LayoutController>
  )
}

export default Profile;