// extracted by mini-css-extract-plugin
export var alignCenter = "form-module--alignCenter--08McC";
export var buttonOutlined = "form-module--buttonOutlined--z40b7";
export var centerDiv = "form-module--centerDiv--IJRtI";
export var centeredError = "form-module--centeredError--G67uU";
export var error = "form-module--error--9LwB0";
export var errorMessage = "form-module--errorMessage--X5Ys5";
export var form = "form-module--form--5wXy9";
export var formContainer = "form-module--formContainer--QzgQX";
export var formNotifications = "form-module--formNotifications--C47np";
export var formRow = "form-module--formRow--VLUDf";
export var formRow2 = "form-module--formRow2--5t3L7";
export var formRow3 = "form-module--formRow3--dcALZ";
export var inputCenter = "form-module--inputCenter--g+3-4";
export var inputContainer = "form-module--inputContainer--Y5bQK";
export var link = "form-module--link--Ljjza";
export var pCenter = "form-module--pCenter--b9JDF";